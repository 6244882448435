.top-bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    height: 30px;
    z-index: 1;
}

.flags{
    padding-left: 20px;
}

.flag {
    padding: 5px;
    height: 25px;
    width: 50px;
    cursor: pointer;
    transition: opacity 0.3s ease;
    opacity: 1;
}

.top-bar .flags .flag:hover,
.top-bar .top-bar-icon:hover {
  opacity: 0.8;
}

.login {
    padding-right: 15px;
}

.top-bar-icon {
    margin: 0 10px;
    width: 30px;
    height: 30px;
    cursor: pointer;
}
