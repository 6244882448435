.control-panel {
    margin-top: 100px;
    padding: 40px;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.control-panel-container{
    position: absolute;
    background-color: #131313;
    width: 100%;
    height: 100%;
}

.control-panel-button {
    margin-left: 20px;
    background: #1E1E1E;
    color: #949073;
    padding: 10px 20px;
    border-radius: 10px;
    border: 1px solid #567C81;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.control-panel-button:hover {
    background-color: #567C81;
    color: #D4D4D4;
}

.control-panel-reactQuill {
    height: 500px;
    margin-top: 40px;
    margin-bottom: 40px;
}

.control-panel table {
    width: 100%;
    margin-top: 100px;
    border-collapse: collapse;
}

.control-panel th,
.control-panel td {
    padding: 8px;
    border: 1px solid #3b3b3b;
    color: #9e9e9e;
}

.control-panel th {
    background-color: #141414;
}

.control-panel-input-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.control-panel input {
    flex-grow: 1;
    padding: 10px;
    border-radius: 10px;

    background-color: rgba(0, 0, 0, 0.2);
    color: white;
    outline: none;
}

.row-button {
    margin-left: 0;
    margin-right: 1px;
    background: #1E1E1E;
    color: #949073;
    padding: 2px 20px;
    border-radius: 0px;
    border: 1px solid #567C81;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.row-button:hover {
    background-color: #567C81;
    color: #D4D4D4;
}

.row-button:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.row-button:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    margin-right: 0;
}

.control-panel-paginator-button {
    margin-left: 0;
    margin-right: 1px;
    background: #1E1E1E;
    color: #949073;
    padding: 2px 20px;
    border-radius: 10px;
    border: 1px solid #567C81;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.control-panel-paginator-button:hover {
    background-color: #567C81;
    color: #D4D4D4;
}

.control-panel-paginator-text{
    color: #D4D4D4;
}

.control-panel-pagination{
    margin-top: 40px;
}