.presentation-tab {
    font-family: Arial, sans-serif;
  }
  
  .presentation-tab h2 {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .presentation-tab h3 {
    margin-bottom: 30px;
    margin-top: 70px;
  }
  
  .presentation-tab p {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 70px;
    text-align: justify;
  }
  
  .requirements {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 80px;
  }
  .requirements table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .requirements > div {
    flex: 1;
    padding: 0 15px;
  }
  
  .requirements h4 {
    margin-bottom: 10px;
    color: #444;
  }
  
  .requirements ul {
    list-style: none;
    padding: 0;
  }
  
  .requirements li {
    background: #f8f8f8;
    padding: 8px 12px;
    margin-bottom: 5px;
    border-left: 3px solid #ccc;
  }
  