.columns-section {
    padding-top: 14rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 75rem;
    margin: 0 auto; 
}

@media (max-width: 600px) {
    .columns-section {
        flex-direction: column;
    }
}