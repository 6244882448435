.footer-logo {
    height: 11.8rem;
    vertical-align: middle;
}

.footer-unity-logo {
    height: 3rem;
    filter: invert(100%);
    vertical-align: middle;
}

.footer-bottom {
    border-top: 0.2rem solid #5F5F5F;
}

.footer-copyright {
    color: #5F5F5F;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
}