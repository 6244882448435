.popup-login {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  animation: fadeIn 0.3s ease;
}

.popup-content {
  width: 250px;
  position: relative;
  background: #1e1e1e;
  padding: 50px;
  border-radius: 8px;
  animation: slideIn 0.3s ease;
}

.popup-content label {
  display: block;
  color: white;
  margin-bottom: 10px;
  padding-bottom: 5px;
  text-align: left;
}

.popup-login .open {
  display: flex;
}

.error-message {
  color: red;
  margin-bottom: 10px;
  word-wrap: break-word;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

.buttons-login-popup {
  display: flex;
  justify-content: center;
}

.login-button {
  background: #1E1E1E; 
  color: #949073; 
  padding: 10px 20px;
  border-radius: 4px;
  border: 1px solid #567C81; 
  cursor: pointer;
  transition: background-color 0.3s ease; 
}

.login-button:hover {
  background-color: #567C81;
  color: #D4D4D4; 
}

.close-button {
  top: 5px;
  right: 0px;
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  border-radius: 4px;
  transition: transform 0.3s ease;
}

.close-button:hover {
  transform: scale(1.1);
}

.popup-content input {
  margin-bottom: 20px;
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
}