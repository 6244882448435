.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: center;
}

body {
  background-color: black;
}

.Footer {
  margin-top: auto;
}