.message {
  position: absolute;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 10px;
  top: 50px;
  left: 0;
}
  
  .success {
    background-color: green;
  }
  
  .error {
    background-color: red;
  }