.middle-column {
    flex: 1;
    color: azure;

    @media (max-width: 600px) {
        order: -1;
    }
}

.middle-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.tabs {
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }
  
  .tabs button {
    padding: 10px 20px;
    margin: 0 5px;
    border: none;
    background-color: transparent;
    color: #ccc; /* Jasny kolor tekstu dla lepszego kontrastu */
    cursor: pointer;
    position: relative;
    transition: color 0.3s;
    font-size: 16px;
  }
  
  .tabs button:hover {
    color: #fff; /* Bardziej wyraźny kolor przy najechaniu */
  }
  
  .tabs button.active {
    color: #fff; /* Jasny kolor dla aktywnej zakładki */
  }
  
  /* Styl dla linii wskazującej aktywną zakładkę */
  .tabs::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-bottom: 2px solid #ccc; /* Jasny kolor linii */
    transition: all 0.3s;
  }
  
  .tabs button.active::after {
    content: '';
    position: absolute;
    bottom: -2px; /* Wyrównanie do dolnej części przycisku */
    left: 0;
    width: 100%;
    border-bottom: 2px solid #fff; /* Bardziej wyraźny kolor linii dla aktywnej zakładki */
  }
  
  
/* Styl animacji dla zawartości zakładek */
.tab-content {
    display: none; /* Ukrywa nieaktywne zakładki */
    opacity: 0;
    transform: translateY(-20px);
  }
  
  .tab-content.active {
    display: block; /* Wyświetla aktywną zakładkę */
    animation: fadeInAnimation 0.3s ease-out forwards;
  }
  
  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }