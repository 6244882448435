.changelog {
  position: relative;
  background-color: #030303;
  color: #d3d3d3;
  border: 1px solid #567c8160;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 20px;
  overflow: hidden;
  width: 612px;
  min-height: 322px;
  max-height: 322px;
  transition: max-height 0.5s ease-in-out;
}

.changelog-open {
  max-height: 2000px;
}

.changelog-title {
  font-size: 20px;
  font-weight: bold;
  font-family: 'Roboto', sans-serif;
  text-align: left;
  margin: 0;
}

.divider {
  height: 1px;
  background-color: #969696;
  border: none;
}

.changelog-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.changelog-author, .changelog-date {
  font-size: 12px;
  font-family: 'Inter', sans-serif;
  margin: 0;
}

.changelog-date {
  text-align: right;
}

.changelog-author {
  text-align: left;
}

.image-divider {
  width: 100%;
}

.changelog-content {
  line-height: 1.5;
  text-align: left;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
}

.changelog-content p {
  margin: 0;
}

.changelog-content ul,
.changelog-content ol {
  margin: 0;
  padding-left: 20px;
}

.changelog-content li {
  margin: 5px 0;
}

.changelog-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-family: 'Inter', sans-serif;
  font-size: 11px;
  font-weight: bold;
  color: #567C81;
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
}
