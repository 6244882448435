.left-column {
    flex: 1;
    color: azure;

    @media (max-width: 600px) {
        width: 50%;
    }
}

.image-container {
    position: relative;
    display: inline-block;
  }

.left-column-header-container {
    position: relative;
  }
  
  .left-column-header-image {
    display: block;
    width: 270px;
  }
  
  .left-column-header-text {
    position: absolute;
    top: 35px;
    left: 50%;
    text-align: center;
    transform: translate(-50%, 0);
    margin-left: 5px;
  }

  .left-column-counter{
    font-size: xx-large;
    margin-top: 40px;
  }
  
  .left-column-downloads-heading {
    font-size: 20px;
    color: #FFFFFF;
    margin: 0;
    padding: 10px;
  }

  .left-column-buy-me-a-coffee{
    margin-top: 50px;
  }

  .left-column-counter.counter-animation {
    animation: counterAnimation 1s;
  }
  
  @keyframes counterAnimation {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1);
    }
  }