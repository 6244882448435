.hero-section {
    position: relative;
}

.download-button {
    position: absolute;
    bottom: -10rem;
    left: 50%;
    transform: translateX(-50%);
    transition: transform 0.3s ease-in-out; /* Dodaj efekt przejścia */
}

.headerLogo-image {
    position: absolute;
    bottom: -3rem;
    height: 400px;
    left: 50%;
    transform: translateX(-50%);
}

.background-image {
    width: 100%;
}

.download-button {
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: contain;
    border: none;
    width: 30rem;
    height: 10rem;
}

.download-button:hover {
    cursor: pointer;
    transform: translateX(-50%) scale(1.05);
}

.download-text {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: azure;
    font-size: 2rem;
    padding: 0 3rem 0.6rem 3rem;
    transition: color 0.3s ease-in-out;
}

.download-text:hover {
    color: #8E886B;
}
